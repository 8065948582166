<template>
  <div>
    <div class="pro-loader" v-if="isLoading">
      <div class="reverse-spinner"></div>
    </div>
    <div v-else>
      <div class="cst-pagination-bar">
        <label
          >Pagina <span>{{ currentPage }}</span> van
          <span>{{ Math.ceil(totalProds / prodsPerPage) }}</span></label
        >

        <div class="view-change ml-0 mobile-work">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>

        <b-pagination
          :total-rows="totalProds"
          :per-page="prodsPerPage"
          :value="currentPage"
          @change="changePage"
          class="cst-pagination"
          aria-controls="cat-item"
        ></b-pagination>
      </div>

      <div class="pagination-bar-down">
        <div class="left">
          <div class="display-drop">
            <label>{{ $t("view") }}:</label>
            <select v-model.number="prodsPerPage">
              <option value="1">1</option>
              <option value="9">9</option>
              <option value="12">12</option>
              <option value="24">24</option>
              <option value="48">48</option>
              <option value="72">72</option>
              <option value="98">98</option>
              <option value="120">120</option>
            </select>
          </div>
          <div class="view-change desktop-work">
            <div @click="paginationConf.isGrid = true" class="grid-view">
              <i class="fas fa-th-large"></i>
            </div>
            <div @click="paginationConf.isGrid = false" class="list-view">
              <i class="fas fa-list"></i>
            </div>
          </div>
        </div>
        <div class="middle" v-if="isMobileScreen">
          <div
            @click="changeMobileCategoryFilterShow"
            class="filter-btn-mobile"
          >
            <i class="fas fa-filter"></i>
            <span>Filter</span>
          </div>
        </div>

        <div class="right">
          <SearchPageContentFilter />
        </div>
      </div>

      <div
        class="row"
        :class="{
          'grid-view': paginationConf.isGrid,
          'list-view': !paginationConf.isGrid,
        }"
      >
        <div
          v-for="product of lists"
          class="col-12 mb-30 col-lg-3 cst-12"
          :key="product.id"
        >
          <ProductCard :product="product" />
        </div>
      </div>

      <div class="cst-pagination-bar">
        <label
          >Pagina <span>{{ currentPage }}</span> van
          <span>{{ Math.ceil(totalProds / prodsPerPage) }}</span></label
        >

        <b-pagination
          :total-rows="totalProducts"
          :per-page="paginationConf.perPage"
          :value="currentPage"
          @change="changePage"
          class="cst-pagination"
          aria-controls="cat-item"
        ></b-pagination>
      </div>

      <div class="pagination-bar-down">
        <div class="left">
          <div class="display-drop">
            <label>{{ $t("view") }}:</label>
            <select v-model.number="paginationConf.perPage">
              <option value="1">1</option>
              <option value="9">9</option>
              <option value="12">12</option>
              <option value="24">24</option>
              <option value="48">48</option>
              <option value="72">72</option>
              <option value="98">98</option>
              <option value="120">120</option>
            </select>
          </div>
          <div class="view-change">
            <div @click="paginationConf.isGrid = true" class="grid-view">
              <i class="fas fa-th-large"></i>
            </div>
            <div @click="paginationConf.isGrid = false" class="list-view">
              <i class="fas fa-list"></i>
            </div>
          </div>
        </div>
        <div class="right">
          <SearchPageContentFilter />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isMobile } from "mobile-device-detect";
import SearchPageContentFilter from "@/base/core/components/search/SearchPageContentFilter";
import ProductCard from "@/base/core/components/category-product/ProductCard.vue";

import chunkArray from "@/base/helpers/chunkArray";

export default {
  name: "SearchFilteredProducts",
  components: {
    ProductCard,
    SearchPageContentFilter,
  },
  props: ["isLoading"],
  data() {
    return {
      paginationConf: {
        isGrid: true,
        currentPageNr: 1,
        totalCount: 0,
        perPage: 12,
      },
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      getMobileCategoryFilterShow: "mobileRes/getMobileCategoryFilterShow",
    }),
    currentSearch() {
      return this.$store.getters["search/getCurrentSearch"];
    },
    totalProducts() {
      return this.$store.getters["search/getShowProducts"].length;
    },
    currentPage() {
      return this.$store.getters["search/getCurrentPage"];
    },
    // currentPage: {
    //   get() {
    //     return this.$store.getters["search/getCurrentPage"];
    //   },
    //   set(value) {
    //     this.$store.commit("search/setCurrentPage", value);
    //   },
    // },
    prodsPerPage: {
      get() {
        return this.$store.getters["search/getProductsPerPage"];
      },
      set(value) {
        this.$store.commit("search/setProductsPerPage", value);
      },
    },
    totalProds() {
      return this.$store.getters["search/getTotalProducts"];
    },
    lists() {
      if (this.$store.getters["search/getShowProducts"].length === 0) {
        return [];
      }
      const items = this.$store.getters["search/getShowProducts"];
      // Return just page of items needed

      return items;

      // return items.slice(
      //   (this.paginationConf.currentPageNr - 1) * this.paginationConf.perPage,
      //   this.paginationConf.currentPageNr * this.paginationConf.perPage
      // );
    },
    productsChunks() {
      return chunkArray(this.$store.getters["search/getShowProducts"], 12);
    },
    isMobileScreen() {
      return isMobile;
    },
  },
  //   methods: mapActions(["setProductStatus"]),
  methods: {
    ...mapActions({
      changeMobileCategoryFilterShow:
        "mobileRes/changeMobileCategoryFilterShow",
    }),
    changePage(pageNo) {
      this.$store.commit("search/setCurrentPage", pageNo);
    },
  },
  watch: {
    currentPage(page) {
      const url = new URL(window.location);
      url.searchParams.set("page", page);
      window.history.pushState({}, "", url);
    },
    currentSearch() {
      const url = new URL(window.location);
      url.searchParams.forEach((value, key) => {
        if (key.startsWith("filter-")) {
          url.searchParams.delete(key);
        }
      });
      if (url.searchParams.has("page")) {
        url.searchParams.delete("page");
      }
      if (url.searchParams.has("sort")) {
        url.searchParams.delete("sort");
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>

