<template>
  <div class="search-list-page">
    <Breadcrumbs />
    <div class="container">
      <div class="row">
        <SearchProductFilter class="col-lg-3" />
        <ProductsListingSearch class="col-lg-9" :isLoading="isLoading" />
      </div>
    </div>
    <CompareButton></CompareButton>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import SearchProductFilter from "@/base/core/components/search/SearchProductFilter";
import ProductsListingSearch from "@/base/core/components/search/ProductsListingSearch";
import CompareButton from "@/base/core/components/common/CompareButton.vue";
import config from "@config";

export default {
  name: "Search",
  components: {
    Breadcrumbs,
    SearchProductFilter,
    ProductsListingSearch,
    CompareButton,
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.$t("search"),
        },
        {
          name: "keywords",
          content: this.$t("search") + ", " + this.searchWord,
        },
        {
          name: "description",
          content:
            this.$t("search") + (this.searchWord ? ": " + this.searchWord : ""),
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("search"),
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
  computed: {
    searchWord() {
      if (typeof this.$route.query.q != "undefined") {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
  },
  watch: {
    searchWord() {
      if (this.searchWord != "") {
        this.$store.dispatch("search/load", { search: this.searchWord });
        const bcrumb = { current: this.$route.query.q, routes: [] };
        this.$store.commit("breadcrumbs/set", bcrumb);
      }
    },
  },
  async mounted() {
    if (this.searchWord != "") {
      await this.$store.dispatch("search/load", { search: this.searchWord });
      await this.applyURLFilters();
    }
    this.isLoading = false;
    const bcrumb = { current: this.$route.query.q, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  methods: {
    async applyURLFilters() {
      const { sort, page } = this.$route.query;

      const totalPages = this.$store.getters["search/getTotalPages"];

      if (
        !isNaN(Number(page)) &&
        Number(page) > 1 &&
        Number(page) <= totalPages
      ) {
        this.$store.commit("search/addCurrentPage", page);
      }

      if (Number(page) > totalPages) {
        const url = new URL(window.location);
        url.searchParams.delete("page");
        window.history.replaceState({}, "", url);
      }

      if (sort) {
        let currentSorting = null;
        if (sort == "name_up") {
          currentSorting = { sortBy: "name", direction: "ASC" };
        } else if (sort == "name_down") {
          currentSorting = { sortBy: "name", direction: "DESC" };
        } else if (sort == "price_up") {
          currentSorting = { sortBy: "price", direction: "ASC" };
        } else if (sort == "price_down") {
          currentSorting = { sortBy: "price", direction: "DESC" };
        }
        this.$store.commit("search/addCurrentSorting", currentSorting);
      }

      const activeFilters = [];
      let activePriceFilter = [];

      const productFilters = this.$store.getters["search/getProductFilters"];

      Object.keys(this.$route.query).forEach((element) => {
        if (element.startsWith("filter-")) {
          const attribute_code = element.replace("filter-", "");

          const attrFilters = productFilters.find(
            (el) => el.attribute_code == attribute_code
          );

          if (attrFilters) {
            if (attribute_code == "price") {
              const [priceMin, priceMax] = this.$route.query[element].split(
                "_"
              );

              const priceFilterMin = this.$store.getters[
                "search/getPriceFilterMin"
              ];

              const priceFilterMax = this.$store.getters[
                "search/getPriceFilterMax"
              ];

              if (!isNaN(parseInt(priceMin)) && !isNaN(parseInt(priceMax))) {
                const min =
                  priceFilterMin > parseInt(priceMin)
                    ? priceFilterMin
                    : parseInt(priceMin);
                const max =
                  priceFilterMax < parseInt(priceMax) || parseInt(priceMax) <= 0
                    ? priceFilterMax
                    : parseInt(priceMax);

                activePriceFilter = [min, max];
              }
            } else {
              this.$route.query[element].split(",").forEach((value) => {
                const activeFilter = attrFilters.options.find(
                  (option) => option.value == value
                );
                if (activeFilter) {
                  const filterBy = {
                    attribute_code: attribute_code,
                    type_label: attrFilters.label,
                    value: value,
                    label: activeFilter.label,
                  };
                  activeFilters.push(filterBy);
                }
              });
            }
          }
        }
      });

      if (activeFilters.length > 0) {
        this.$store.commit("search/addActiveFilter", activeFilters);
      }

      if (activePriceFilter.length > 0) {
        this.$store.commit("search/addPriceFilter", activePriceFilter);
      }

      if (activeFilters.length > 0 || activePriceFilter.length > 0) {
        await this.$store.dispatch("search/loadSearchFilters");
      } else if (sort || (page && Number(page) <= totalPages)) {
        await this.$store.dispatch("search/loadProductsBySearch");
      }
    },
  },
  destroyed() {
    this.$store.commit("search/setLoadProducts", false);
    Logger.debug(
      "DESTROYED",
      "Search.vue",
      this.$store.getters["search/getLoadProducts"]
    )();
  },

  data() {
    return {
      isLoading: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-list-page {
  margin-bottom: 80px;
}
</style>
