<template>
  <section class="page-content product-output-list">
    <SearchFilteredProducts :isLoading="isLoading" />
  </section>
</template>

<script>
import SearchFilteredProducts from "@/base/core/components/search/SearchFilteredProducts";
export default {
  name: "SearchPageContent",
  components: {
    SearchFilteredProducts,
  },
  props: ["isLoading"],
};
</script>

<style lang="scss" scoped>
</style>
