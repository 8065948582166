<template>
  <div>
    <Breadcrumbs />
    <section class="brands-section">
      <b-container>
        <b-row>
          <b-col lg="3">
            <BrandsSidebarLinks :sidebarData="brands" />
          </b-col>

          <b-col lg="9">
            <div class="bottom-txt">
              <div class="extra-info" v-html="getCmsData.content"></div>
            </div>
            <div class="cst-pagination-bar">
              <label
                >Pagina <span>{{ paginationConf.currentPageNr }}</span> van
                <span
                  >{{
                    Math.ceil(
                      paginationConf.totalCount / paginationConf.perPage
                    )
                  }}
                </span></label
              >

              <!-- pagination -->
              <b-pagination
                v-model="paginationConf.currentPageNr"
                :total-rows="paginationConf.totalCount"
                :per-page="paginationConf.perPage"
                class="cst-pagination"
                aria-controls="cat-item"
              ></b-pagination>
              <!-- @change="tmp" -->
            </div>

            <!-- per page -->
            <div class="pagination-bar-down">
              <div class="left">
                <div class="display-drop">
                  <label>{{ $t("view") }}: </label>
                  <select v-model.number="paginationConf.perPage">
                    <!-- <option value="1">1</option> -->
                    <option value="1">1</option>
                    <option value="9">9</option>
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="48">48</option>
                    <option value="72">72</option>
                    <option value="98">98</option>
                    <option value="120">120</option>
                  </select>
                </div>
                <div class="view-change">
                  <div @click="paginationConf.isGrid = true" class="grid-view">
                    <i class="fas fa-th-large"></i>
                  </div>
                  <div @click="paginationConf.isGrid = false" class="list-view">
                    <i class="fas fa-list"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- dddd{{ lists }} -->
            <!-- <span class="d-none d-lg-block page-title">{{ $t("brands") }}</span> -->
            <b-row
              :class="{
                'grid-view': paginationConf.isGrid,
                'list-view': !paginationConf.isGrid,
              }"
            >
              <b-col
                cols="6"
                md="4"
                lg="3"
                v-for="(brandData, index) in lists"
                :key="`bp` + index"
                class="brand-box cst-12"
              >
                <b-link
                  v-if="brandData.category != null"
                  :to="`/` + brandData.category.url_path"
                  class="category-link"
                >
                  <div class="category-box">
                    <div class="img">
                      <img :src="brandData.logo" :alt="brandData.title" />
                    </div>
                    <div class="text">
                      <p>{{ brandData.title }}</p>
                    </div>
                    <div class="list-action-btn">
                      <b-link
                        v-if="brandData.category != null"
                        :to="`/` + brandData.category.url_path"
                        class="c-btn c-btn-primary"
                        >{{ $t("view_category") }}</b-link
                      >
                    </div>
                  </div>
                </b-link>
                <div class="category-box" v-if="brandData.category == null">
                  <div class="img">
                    <img :src="brandData.logo" :alt="brandData.title" />
                  </div>
                  <div class="text">
                    <p>{{ brandData.title }}</p>
                  </div>
                  <div class="list-action-btn">
                    <b-link
                      v-if="brandData.category != null"
                      :to="`/` + brandData.category.url_path"
                      class="c-btn c-btn-primary"
                      >{{ $t("view_category") }}</b-link
                    >
                  </div>
                </div>
              </b-col>
            </b-row>

            <div class="cst-pagination-bar">
              <label
                >Pagina <span>{{ paginationConf.currentPageNr }}</span> van
                <span>{{
                  Math.ceil(paginationConf.totalCount / paginationConf.perPage)
                }}</span></label
              >

              <!-- pagination -->
              <b-pagination
                v-model="paginationConf.currentPageNr"
                :total-rows="paginationConf.totalCount"
                :per-page="paginationConf.perPage"
                class="cst-pagination"
                aria-controls="cat-item"
              ></b-pagination>
              <!-- @change="tmp" -->
            </div>

            <!-- per page -->
            <div class="pagination-bar-down">
              <div class="left">
                <div class="display-drop">
                  <label>{{ $t("view") }}: </label>
                  <select v-model.number="paginationConf.perPage">
                    <!-- <option value="1">1</option> -->
                    <option value="1">1</option>
                    <option value="9">9</option>
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="48">48</option>
                    <option value="72">72</option>
                    <option value="98">98</option>
                    <option value="120">120</option>
                  </select>
                </div>
                <div class="view-change">
                  <div @click="paginationConf.isGrid = true" class="grid-view">
                    <i class="fas fa-th-large"></i>
                  </div>
                  <div @click="paginationConf.isGrid = false" class="list-view">
                    <i class="fas fa-list"></i>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import BrandsSidebarLinks from "@/base/core/components/brands/BrandsSidebarLinks.vue";
import config from "@config";

export default {
  name: "BrandList",
  components: {
    Breadcrumbs,
    BrandsSidebarLinks,
  },
  data() {
    return {
      paginationConf: {
        isGrid: true,
        currentPageNr: 1,
        totalCount: this.$store.getters["product/getBrandSlider"].length,
        perPage: 12,
      },
    };
  },
  mounted() {
    const bcrumb = { current: this.$t("brands"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  computed: {
    brands() {
      return this.$store.getters["product/getBrandSlider"];
    },
    getCmsData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`]("merken");
    },

    lists() {
      if (this.brands.length === 0) {
        return [];
      }
      const items = this.brands;
      // Return just page of items needed
      return items.slice(
        (this.paginationConf.currentPageNr - 1) * this.paginationConf.perPage,
        this.paginationConf.currentPageNr * this.paginationConf.perPage
      );
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title
            : "",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords
            : "",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description
            : "",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Brands",
      // all titles will be injected into this template
      link: [
        {
          rel: "canonical",
          href: config.app_url + this.$route.fullPath,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.brands-section {
  margin-bottom: 80px;
  .brand-box {
    margin-bottom: 30px;
  }
}

.category-box {
  border: 1px solid $borderColor;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 18px;
  text-align: center;
  font-size: 12px;
  line-height: 1.1;
  transition: 0.5s;
  height: 100%;
  &:hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
    text-decoration: none;
  }
  .img {
    height: 185px;
    display: flex;
    align-items: center;
    z-index: -1;
    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }
  }
  .text {
    width: 100%;
    font-weight: 600;
    color: $black;
    font-size: 15px;
    padding: 15px 0;
    height: calc(100% - 185px);
    display: block;
    text-align: left;
  }
}
.bottom-txt {
  font-size: 12px;
  line-height: 1.72;
  padding-bottom: 14px;
  // padding-top: 25px;
  .extra-info {
    span {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
}
@media screen and (max-width: 670px) {
  .category-box {
    padding: 10px;
    .img {
      height: 125px;
    }
    .text {
      height: auto;
      padding-bottom: 0;
    }
  }
}
</style>
